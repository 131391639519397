<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        md="9"
      >
        <b-card
          :title="$t('Edit Connectjsr')"
        >
          <b-row>
            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <b-form-input
                  v-model="data.link"
                  class="mt-2"
                  :state="errors.link ?false:null"
                  :placeholder="$t('link Facebook')"
                />
                <small
                  v-if="errors.link"
                  class="text-danger"
                >{{ errors.link[0] }}</small>
                <label>{{ $t('link') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <b-form-input
                  v-model="data.linkt"
                  class="mt-2"
                  :state="errors.linkt ?false:null"
                  :placeholder="$t('linkt Telegram')"
                />
                <small
                  v-if="errors.linkt"
                  class="text-danger"
                >{{ errors.linkt[0] }}</small>
                <label>{{ $t('linkt') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <b-form-input
                  v-model="data.linki"
                  class="mt-2"
                  :state="errors.linki ?false:null"
                  :placeholder="$t('linki Instagram')"
                />
                <small
                  v-if="errors.linki"
                  class="text-danger"
                >{{ errors.linki[0] }}</small>
                <label>{{ $t('linki') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <b-form-input
                  v-model="data.linktw"
                  class="mt-2"
                  :state="errors.linktw ?false:null"
                  :placeholder="$t('linktw Twitter')"
                />
                <small
                  v-if="errors.linktw"
                  class="text-danger"
                >{{ errors.linktw[0] }}</small>
                <label>{{ $t('linktw') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <b-form-input
                  v-model="data.number"
                  class="mt-2"
                  :state="errors.number ?false:null"
                  :placeholder="$t('number')"
                />
                <small
                  v-if="errors.number"
                  class="text-danger"
                >{{ errors.number[0] }}</small>
                <label>{{ $t('number') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <b-form-input
                  v-model="data.email"
                  class="mt-2"
                  :state="errors.email ?false:null"
                  :placeholder="$t('email')"
                />
                <small
                  v-if="errors.email"
                  class="text-danger"
                >{{ errors.email[0] }}</small>
                <label>{{ $t('email') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <b-form-input
                  v-model="data.lan"
                  class="mt-2"
                  :state="errors.lan ?false:null"
                  :placeholder="$t('lan')"
                />
                <small
                  v-if="errors.lan"
                  class="text-danger"
                >{{ errors.lan[0] }}</small>
                <label>{{ $t('lan') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <b-form-input
                  v-model="data.lat"
                  class="mt-2"
                  :state="errors.lat ?false:null"
                  :placeholder="$t('lat')"
                />
                <small
                  v-if="errors.lat"
                  class="text-danger"
                >{{ errors.lat[0] }}</small>
                <label>{{ $t('lat') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <b-form-input
                  v-model="data.address"
                  class="mt-2"
                  :state="errors.address ?false:null"
                  :placeholder="$t('address')"
                />
                <small
                  v-if="errors.address"
                  class="text-danger"
                >{{ errors.address[0] }}</small>
                <label>{{ $t('address') }}</label>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        md="3"
      >
        <!-- <b-card>
          <b-row>
            <b-col
              cols="12"
              md=""
              class="d-flex justify-content-center"
            >
              <b-row>
                <b-col
                  cols="12"
                  class="d-flex justify-content-center"
                >
                  <upload
                    v-model="data.avatar_id"
                    @on-file-error="UpdateFileError"
                  />
                </b-col>
                <b-col cols="12">
                  <b-alert
                    v-if="fileErrors.length>0"
                    variant="danger"
                    show
                  >
                    <h4 class="alert-heading">
                      {{ $t('Upload Image Error') }}
                    </h4>
                    <div class="alert-body">
                      <span>
                        <ul
                          v-for="(val,index) in fileErrors"
                          :key="index"
                        >
                          <li>{{ val }}</li>
                        </ul>
                      </span>
                    </div>
                  </b-alert>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card> -->
        <b-card>
          <b-row>
            <b-col
              cols="12"
            >
              <b-button
                class="w-100 mb-1"
                variant="gradient-success"
                @click="update"
              >
                {{ $t('Update') }}
              </b-button>
              <b-button
                class="w-100"
                variant="gradient-danger"
                @click="data={},errors=[]"
              >
                {{ $t('Reset Form') }}
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        md="3"
      >
        <b-row />
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: {},
      errors: [],
      fileErrors: '',

    }
  },
  computed: {

    Element() { return this.$store.getters['connectjsr/GetElement'] },
  },
  created() {
    this.getData()
  },
  methods: {
    setBoolean(val) {
      if (this.data[val] === undefined) { this.data[val] = false }
    },
    UpdateFileError(variable) {
      this.fileErrors = variable
    },
    getData() {
      this.$store.dispatch('connectjsr/GetElement', this.$route.params.connectjsr).then(() => {
        this.data = this.Element
      })
    },
    update() {
      this.errors = []
      this.$store.dispatch('connectjsr/Update', this.data).then(() => {
      }).catch(error => {
        this.errors = error.response.data.errors
      })
    },
  },
}
</script>
